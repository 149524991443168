<template>
  <div class="w-full flex-1 text-center">
    <div class="w-full h-full" v-for="(game, index) in games" :key="index">
      <single-elimination
        v-if="game.bracket.length > 0 && stage.competition_format === 'single_elimination'"
        :bracket="game.bracket"
        :players="game.participants"
        :series="game.series"
        :stage="stage"
        :userType="userType"
        :tournamentStageType="tournamentStageType"
        :hoveredPlayerId="hoveredPlayerId"
        :setHoveredPlayerId="(val) => { hoveredPlayerId = val }"
        @updateParticipant="updateParticipant"
        @openModal="openModal"
      />

      <swiss
        v-if="game.bracket.length > 0 && stage.competition_format === 'swiss'"
        :bracket="game.bracket"
        :players="game.participants"
        :series="game.series"
        :stage="stage"
        :userType="userType"
        :tournamentStageType="tournamentStageType"
        :updatedMatch="updatedMatch"
        :hoveredPlayerId="hoveredPlayerId"
        :setHoveredPlayerId="(val) => { hoveredPlayerId = val }"
        @updateParticipant="updateParticipant"
        @updateMatchPlayer="updateMatchPlayer"
        @updateBracket="getBracket"
        @openModal="openModal"
      />

      <div v-if="game.bracket.length ==0" class="w-full">
        <p class="text-lg font-medium my-3" v-if="stage.type === 'TournamentGroupStage'">Series {{ game.series }}</p>
        <p>No match required</p>
      </div>

      <match-modal
        v-if="showModal"
        :match="selectedMatch"
        :nextMatches="selectedNextMatches"
        :canTie="stage.competition_format === 'swiss'"
        @closeModal="closeModal"
        @updateMatch="updateMatch"
      />
    </div>
  </div>
</template>

<script>
import SingleElimination from './components/SingleElimination.vue';
import Swiss from './components/Swiss.vue';
import MatchModal from './components/MatchModal.vue';
import axios from 'axios';
const HEADERS = { 'Accept': 'application/json' };

export default {
  components: {
    SingleElimination,
    Swiss,
    MatchModal
  },
  props: {
    tournamentPath: String,
    stageId: String,
    userType: String,
    tournamentStageType: String
  },
  data() {
    return {
      games: [],
      stage: {},
      selectedMatch: {},
      selectedNextMatches: [],
      updatedMatch: {},
      hoveredPlayerId: 0,
      showModal: false
    };
  },
  mounted() {
    this.getBracket();
  },
  methods: {
    getBracket() {
      axios.get(
        `/tournaments/${this.tournamentPath}/stages/${this.stageId}`, { headers: HEADERS }
      ).then(({ data }) => {
        this.games = data.game;
        this.stage = data.stage;
      });
    },
    updateParticipant(participant, newSeed) {
      axios.put(
        `/tournaments/${this.tournamentPath}/participants/${participant.id}`,
        { seed: newSeed },
        { headers: {...HEADERS, 'X-CSRF-Token': this.getCsrfToken() } }
      ).then(() => {
        participant.seed = newSeed;
      });
    },
    openModal(match, selectedNextMatches) {
      this.selectedMatch = match;
      this.selectedNextMatches = selectedNextMatches;
      this.showModal = true;
    },
    closeModal() {
      this.selectedMatch = {};
      this.selectedNextPlayer1Match = {};
      this.selectedNextPlayer2Match = {};
      this.showModal = false;
    },
    updateMatch(match, nextMatches, player1_score, player2_score, winner_id) {
      axios.put(
        `/tournaments/${this.tournamentPath}/matches/${match.id}`,
        { tournament_match: { player1_score, player2_score, winner_id } },
        { headers: {...HEADERS, 'X-CSRF-Token': this.getCsrfToken() } }
      ).then(({ data }) => {
        match.player1_score = data.match.player1_score;
        match.player2_score = data.match.player2_score;
        match.winner_id = data.match.winner_id;
        const winner = match.player1_id === data.match.winner_id ? match.player1 : match.player2;
        const loser = match.player1_id === data.match.winner_id ? match.player2 : match.player1;

        nextMatches.map(_match => {
          if (_match.player1_pre_match_id == match.id) {
            _match.player1_id = _match.player1_pre_match_loser ? loser.id : winner.id;
            _match.player1 = _match.player1_pre_match_loser ? loser : winner;
          } else if (_match.player2_pre_match_id == match.id) {
            _match.player2_id = _match.player2_pre_match_loser ? loser.id : winner.id;
            _match.player2 = _match.player2_pre_match_loser ? loser : winner;
          }
        });
        this.updatedMatch = match;
      })
    },
    updateMatchPlayer(match, player_index, player_id) {
      const player_key = player_index == 0 ? 'player1_id' : 'player2_id';
      const params = {}
      params[player_key] = player_id
      axios.put(
        `/tournaments/${this.tournamentPath}/matches/${match.id}`,
        { tournament_match: params },
        { headers: {...HEADERS, 'X-CSRF-Token': this.getCsrfToken() } }
      ).then(({ data }) => {
        match[player_key] = data[player_key];
      })
    },
    getCsrfToken() {
      return document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content');
    }
  }
}
</script>
