import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="community--profile"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.style.resize = "none";
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`;
    this.inputTarget.style.overflow = "hidden";
  }

  resize(event){
    event.target.style.height =  `${event.target.scrollHeight}px`;
  }
}
