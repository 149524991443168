import { createApp } from 'vue'
import ImageSlider from '../../components/home/ImageSlider.vue'

document.addEventListener('turbolinks:load', () => {
  const targetSlider = document.getElementById('home-slider')
  if (targetSlider) {
    const app = createApp(ImageSlider, targetSlider.dataset)
    app.mount(targetSlider)
  }
})
