<template>
  <swiper class="max-h-full flex relative"
    :slidesPerView="2"
    :centeredSlides="true"
    :loop="true"
    :initialSlide="0"
    :modules="modules"
    :navigation="{nextEl: '#slider-right-button', prevEl: '#slider-left-button'}"
  >
    <div id="slider-left-button" class="p-2 bg-black/50 rounded-full absolute left-[7%] sm:left-[12%] top-1/2 translate-y-[-50%] z-20 hover:bg-black/90 cursor-pointer transition-all duration-300">
      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z" fill="rgba(255,255,255,1)"></path></svg>
    </div>
    <swiper-slide v-if="sliderImages.length" v-for="(image) in sliderImages.split(',')">
      <img class="object-cover" style="aspect-ratio:2/1;" :src="image"/>
    </swiper-slide>
    <div id="slider-right-button" class="p-2 bg-black/50 rounded-full absolute right-[7%] sm:right-[12%] top-1/2 translate-y-[-50%] z-20 hover:bg-black/90 cursor-pointer transition-all duration-300">
      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" fill="rgba(255,255,255,1)"></path></svg>
    </div>
  </swiper>
</template>

<script>
  import { Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import 'swiper/css'

  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },

    props: {
      sliderImages: {
        type: String,
        default: '',
      }
    },
  }
</script>

<style>
  .swiper-slide {
    transition: all 200ms linear;
    transform: scale(0.7) !important;
    filter: blur(4px);
  }
  .swiper-slide-active {
    transform: scale(1.3) !important;
    filter: blur(0);
  }
</style>
