import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs';

// Connects to data-controller="hobbies--tournament-participants"
export default class extends Controller {
  static values = { participantsUrl: String, seriesSize: Number }
  static targets = [ 'listParticipants' ]

  connect () {
    const that = this;

    // ToDo: disable when a stage of tournament is started
    Sortable.create(this.listParticipantsTarget, {
      animation: 150,
      handle: ".drag-handler",
      onEnd: function (evt) {
        const id = evt.item.attributes['data-id'].value;
        const headers = {
          'Content-Type': 'application/json',
          'X-CSRF-Token': that.getMetaValue('csrf-token')
        };

        fetch(`${that.participantsUrlValue}/${id}`, {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify({ seed: evt.newIndex })
        }).then(response => response.json())
        .then(res_body => {
          let seriesName = 'A';
          let seriesIndex = 0;
          res_body.forEach((participant, index) => {
            const indexCell = document.querySelector(`[data-id='${participant.id}']`).querySelector('.participant-seed');
            indexCell.textContent = `${index + 1}`;
            const seriesCell = document.querySelector(`[data-id='${participant.id}']`).querySelector('.participant-series');
            if (seriesCell) {
              const newSeriesIndex = Math.floor(index / that.seriesSizeValue);
              seriesName = newSeriesIndex != seriesIndex ? that.getNextSeriesName(seriesName) : seriesName;
              seriesIndex = newSeriesIndex;
              seriesCell.textContent = seriesName;
            }
          });
        }).catch(error => {
          console.log(error);
        });
      },
    })
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }

  getNextSeriesName(key) {
    if (key === '') {
      return 'A'
    } else if (key === 'Z') {
      return String.fromCharCode(key.charCodeAt() - 25) + String.fromCharCode(key.charCodeAt() - 25); // AA
    } else {
      var lastChar = key.slice(-1);
      var sub = key.slice(0, -1);
      if (lastChar === 'Z') {
        return this.getNextSeriesName(sub) + String.fromCharCode(lastChar.charCodeAt() - 25);
      } else {
        return sub + String.fromCharCode(lastChar.charCodeAt() + 1);
      }
    }
  };
}
