<template>
  <div @click.self="closeModal" class="fixed top-0 left-0 right-0 z-50 bg-gray-500/50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-xl max-h-full mx-auto mt-[10%]">
      <div class="relative bg-white rounded-lg shadow">
        <div class="flex items-start justify-between p-4 rounded-t">
          <h3 class="text-xl font-semibold">
            Match detail
          </h3>
          <button @click="closeModal" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="p-6 space-y-6">
          <table class="table-auto border-spacing-2 bg-[#F8FAFC] w-full">
            <thead>
              <tr class="text-left">
                <th class="p-2 font-medium text-sm md:text-base w-3/5">Participant</th>
                <th class="p-2 font-medium text-sm md:text-base w-1/5">Score</th>
                <th class="w-1/5"></th>
              </tr>
            </thead>
            <tbody class="text-left">
              <tr>
                <td class="p-2">{{ match.player1.user.username || match.player1.user.email }}</td>
                <td class="p-2"><input type="number" v-model="player1_score" :disabled="!canEdit" class="w-16 p-1 border border-gray-500 rounded" /></td>
                <td class="p-2"><span v-if="player1_score > player2_score">win</span></td>
              </tr>
              <tr>
                <td class="p-2">{{ match.player2.user.username || match.player2.user.email }}</td>
                <td class="p-2"><input type="number"  v-model="player2_score" :disabled="!canEdit" class="w-16 p-1 border border-gray-500 rounded" /></td>
                <td class="p-2"><span v-if="player2_score > player1_score">win</span></td>
              </tr>
            </tbody>
          </table>
          <button
            :disabled="isNaN(parseInt(player1_score)) || isNaN(parseInt(player2_score)) || (!canTie && player1_score === player2_score) || !canEdit"
            @click="updateMatch"
            class="text-white text-xs md:text-sm mx-auto px-5 py-2 bg-[#F3218C] rounded text-white flex gap-2 items-center text-sm whitespace-nowrap border-2 border-[#F3218C] hover:bg-white hover:text-[#F3218C] disabled:bg-gray-300 disabled:border-gray-300 disabled:text-white transition-all duration-300"
          >
            Submmit Score
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    match: Object,
    nextMatches: Array,
    canTie: Boolean
  },
  data() {
    return {
      player1_score: 0,
      player2_score: 0,
      canEdit: false,
    }
  },
  mounted() {
    this.player1_score = this.match.player1_score;
    this.player2_score = this.match.player2_score;
    this.canEdit = !this.nextMatches.map(_match => {
      return _match.player1_pre_match_id == this.match.id ? _match.player1_score : _match.player2_score;
    }).some(Boolean);
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    updateMatch() {
      const winner_id = this.getWinner()
      this.$emit('update-match',
        this.match,
        this.nextMatches,
        this.player1_score,
        this.player2_score,
        winner_id);
      this.closeModal();
    },
    getWinner() {
      if (this.player1_score > this.player2_score) {
        return this.match.player1.id;
      } else if (this.player1_score == this.player2_score) {
        return null;
      } else {
        return this.match.player2.id;
      }
    }
  }
}
</script>
