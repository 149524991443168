<template>
  <div class="w-full overflow-auto flex flex-col" style="webkit-overflow-scrolling: touch">
    <p class="text-lg font-medium my-3 float-left" v-if="stage.type === 'TournamentGroupStage'">Series {{ series }}</p>
    <div class="w-full"
      v-for="(matches_round, round) in matchesOfRound"
      :key="round"
    >
      <p class="text-lg font-medium my-3 text-center">Round {{ round }}</p>
      <div class="flex h-full w-full flex-wrap">
        <div v-for="match in matches_round"
          class="mr-2"
          :style="{'width': `${matchDimensions.width}px`, 'height': `${matchDimensions.height}px`}"
          :key="match.id"
          @mouseover="match.showEdit = true"
          @mouseleave="match.showEdit = false; setHoveredPlayerId(0)"
        >
          <div class="flex text-sm" style="height: 54px">
            <div class="flex flex-col h-full">
              <div class="h-1/2 tournament-player_prefix mb-px rounded-l-sm pt-0.5">
                <span v-if="match.player1">{{ match.players[0]?.seed + 1 }}</span>
              </div>
              <div class="h-1/2 tournament-player_prefix rounded-l-sm pt-0.5">
                <span v-if="match.player2">{{ match.players[1]?.seed + 1 }}</span>
              </div>
            </div>
            <div class="flex-1 text-white h-full w-2/3">
              <draggable
                v-if="canDrop[round]"
                class="flex flex-col tournament-match_drag"
                :class="{'h-full': match.players.length === 2, 'h-1/2': match.players.length === 1 }"
                v-model="match.players"
                item-key="id"
                @end="handleDragEndItem"
                ghost-class="ghost"
                :group="{ name: 'player', put: false }"
                :sort="false"
              >
                <template #item="{element}" >
                  <div :data-match-id="match.id" :data-player-id="element.id"
                    :class="{
                      'h-1/2': match.players.length === 2,
                      'bg-orange-100': element.id === hoveredPlayerId
                    }"
                    class="w-full flex-1 tournament-player pt-0.5 cursor-pointer"
                    @mouseover="setHoveredPlayerId(element.id)"
                  >
                    {{ element.user?.username || element.user.email }}
                  </div>
                </template>
              </draggable>
              <div class="flex flex-col h-full">
                <div v-if="!match.player1 || !canDrop[round]"
                  class="w-full h-1/2 tournament-player mb-px pt-0.5"
                  :class="{ 'bg-orange-100': match.player1_id && match.player1_id === hoveredPlayerId }"
                  @mouseover="setHoveredPlayerId(match.player1_id)"
                >
                  {{ match.player1?.user?.username || match.player1?.user?.email }}
                </div>
                <div v-if="!match.player2 || !canDrop[round]"
                  class="w-full h-1/2 tournament-player pt-0.5"
                  :class="{ 'bg-orange-100': match.player2_id && match.player2_id === hoveredPlayerId }"
                  @mouseover="setHoveredPlayerId(match.player2_id)"
                >
                  {{ match.player2?.user?.username || match.player2?.user?.email }}
                </div>
              </div>
            </div>
            <div class="flex flex-col h-full text-white relative">
              <div class="h-1/2 tournament-player_suffix rounded-r-sm mb-px pt-0.5"
                :class="{'bg-[#FF7324]': match.winner_id && match.winner_id == match.player1.id}">
                {{ match.player1_score !== null ?  match.player1_score:  '-' }}
              </div>
              <div class="h-1/2 tournament-player_suffix rounded-r-sm pt-0.5"
                :class="{'bg-[#FF7324]': match.winner_id && match.winner_id == match.player2.id}">
                {{ match.player2_score !== null ? match.player2_score : '-' }}
              </div>
              <div class="tournament-match_edit bg-gray-700/50 rounded"
                v-if="['admin', 'editor'].includes(userType) && stage.status ==='inprogress' && match.showEdit && match.player1 && match.player2"
              >
                <button class="mx-auto mt-1" @click="showMatchModal(match)">
                  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.94666 13.7193C2.75965 13.719 2.58137 13.6401 2.45533 13.502C2.32696 13.365 2.26317 13.1797 2.28 12.9926L2.44333 11.1966L9.98866 3.65398L12.3467 6.01131L4.80333 13.5533L3.00733 13.7166C2.98666 13.7186 2.966 13.7193 2.94666 13.7193ZM12.8173 5.53998L10.46 3.18264L11.874 1.76864C11.999 1.64346 12.1687 1.57312 12.3457 1.57312C12.5226 1.57312 12.6923 1.64346 12.8173 1.76864L14.2313 3.18264C14.3565 3.30769 14.4269 3.47737 14.4269 3.65431C14.4269 3.83125 14.3565 4.00093 14.2313 4.12598L12.818 5.53931L12.8173 5.53998Z" fill="white"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  props: {
    bracket: Array,
    players: Array,
    series: String,
    stage: Object,
    userType: String,
    tournamentStageType: String,
    updatedMatch: Object,
    hoveredPlayerId: Number,
    setHoveredPlayerId: Function
  },
  data() {
    return {
      matchDimensions: {
        height: 70,
        width: 210
      },
      svgPadding: 20,
      matches: [],
      matchesOfRound: {},
      canDrop: {},
    };
  },
  mounted() {
    this.initMatches();
  },
  watch: {
    updatedMatch: function() {
      const match = this.matches.find(_match => _match.id === this.updatedMatch.id);
      if (match?.id) {
        const nextRound = this.matchesOfRound?.[match.round + 1]
        const currentRound = this.matchesOfRound[match.round].every(_match => _match.player1_score !== null)
        if (currentRound &&  nextRound && !nextRound.some(_match => _match.player1_score !== null)) {
          this.$emit('update-bracket');
        }

        this.updateCanDrop();
      }
    },
    bracket: function() {
      this.initMatches();
    }
  },
  methods: {
    initMatches() {
      this.matches = this.bracket.sort((curr, prev) => curr.round - prev.round).map(match => {
        const player1 = this.players.find(player => player.id === match.player1_id)
        const player2 = this.players.find(player => player.id === match.player2_id)
        const players = [player1, player2].filter(Boolean)
        return { ...match, players, player1, player2 }
      });
      this.matchesOfRound = this.matches.reduce(function (arr, match) {
        arr[match.round] = arr[match.round] || [];
        arr[match.round].push(match);
        return arr;
      }, Object.create(null));
      this.updateCanDrop()
    },
    handleDragEndItem(event) {
      const fromPlayer = parseInt(event.item.dataset.playerId);
      const toPlayer = parseInt(event.originalEvent.target.dataset.playerId);
      if (fromPlayer !== toPlayer) {
        const fromMatch = this.matches.find(match => match.id == parseInt(event.item.dataset.matchId));
        const fromPlayerIndex = fromMatch.players.findIndex(player => player.id == fromPlayer);
        const toMatch = this.matches.find(match => match.id == parseInt(event.originalEvent.target.dataset.matchId));
        const toPlayerIndex = toMatch.players.findIndex(player => player.id == toPlayer);
        if (this.stage.status === 'pending' && (this.tournamentStageType === 'single_stage' || this.stage.type === 'TournamentGroupStage')) {
          this.swapParticipant(fromMatch, fromPlayerIndex, toMatch, toPlayerIndex);
        } else {
          this.updateMatchPlayer(fromMatch, fromPlayerIndex, toMatch, toPlayerIndex);
        }
      }
    },
    swapParticipant(fromMatch, fromIndex, toMatch, toIndex) {
      const fromParticipant = fromMatch.players[fromIndex];
      const toParticipant = toMatch.players[toIndex];

      this.$emit('update-participant', fromParticipant, toParticipant.seed);
      this.$emit('update-participant', toParticipant, fromParticipant.seed);

      toMatch.players[toIndex] = fromParticipant;
      fromMatch.players[fromIndex] = toParticipant;
    },
    updateMatchPlayer(fromMatch, fromIndex, toMatch, toIndex) {
      const fromParticipant = fromMatch.players[fromIndex];
      const toParticipant = toMatch.players[toIndex];

      this.$emit('update-match-player', fromMatch, fromIndex, toParticipant.id);
      this.$emit('update-match-player', toMatch, toIndex, fromParticipant.id);

      toMatch.players[toIndex] = fromParticipant;
      toMatch[toIndex === 0 ? 'player1' : 'player2'] = fromParticipant;
      fromMatch.players[fromIndex] = toParticipant;
      fromMatch[fromIndex === 0 ? 'player1' : 'player2'] = toParticipant;
    },
    showMatchModal(match) {
      const nextMatches = this.matches.filter(_match => {
        return _match.player1_pre_match_id === match.id ||
        _match.player2_pre_match_id === match.id
      });
      this.$emit('open-modal', match, nextMatches);
    },
    updateCanDrop() {
      Object.keys(this.matchesOfRound).forEach(round => {
        if (!['admin', 'editor'].includes(this.userType)) {
          return false
        } else if (this.stage.status === 'pending') {
          this.canDrop[round] = this.tournamentStageType === 'single_stage' || this.stage.type === 'TournamentGroupStage';
        } else {
          this.canDrop[round] = this.matchesOfRound[round].every(match => match.player1_score == null)
        }
      })
    }
  }
}
</script>

<style>
.tournament-player_prefix {
  width: 24px;
  color: #23252d;
  background-color: #94A3B8;
}

.tournament-player_suffix {
  width: 30px;
  background-color: #94A3B8;
}

.tournament-player {
  color: #64748B;
  background-color: #E2E8F0;
}

.tournament-match_edit {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 14px;
  right: -28px;
}

.tournament-match_drag :first-child {
  margin-bottom: 1px;
}
</style>
