import { createApp } from 'vue/dist/vue.esm-bundler'
import Bracket from '../../components/tournament/Bracket.vue'

document.addEventListener('turbolinks:load', () => {
  renderVueComponent('vue-bracket-group-stage', Bracket)
  renderVueComponent('vue-bracket-final-stage', Bracket)
})

function renderVueComponent(selector, component) {
  const element = document.getElementById(selector)
  if (element) {
    const tournamentPath = element.dataset.tournamentPath
    const stageId = element.dataset.stageId
    const userType = element.dataset.userType
    const tournamentStageType = element.dataset.tournamentStageType
    const app = createApp(component, { tournamentPath, stageId, userType, tournamentStageType })
    app.mount(element)
  }
}
