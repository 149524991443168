import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="hobbies--comment-reactions"
export default class extends Controller {
  static values = { likeUrl: String, unLikeUrl: String, currentUserLiked: Boolean }
  static targets = ['likeButton', 'likeCount']

  toggleLike() {
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.getMetaValue('csrf-token')
    }

    if (this.currentUserLikedValue) {
      fetch(this.unLikeUrlValue, {
        method: 'DELETE',
        headers: headers
      }).then(response => response.json())
        .then(res_body => {
          if (res_body.success) {
            let likeCount = this.likeCountTarget.innerText;

            likeCount--
            this.likeButtonTarget.removeAttribute('class');
            this.likeButtonTarget.classList.add('text-gray-400');
            this.likeCountTarget.innerText = likeCount
            this.currentUserLikedValue = false
          } else {
            console.log(res_body.error)
          }
        })
        .catch(error => {})
    } else {
      fetch(this.likeUrlValue, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({comment_reaction: {react_type: 0}})
      }).then(response => response.json())
        .then(res_body => {
          if (res_body.success) {
            let likeCount = this.likeCountTarget.innerText;

            likeCount++
            this.likeButtonTarget.removeAttribute('class');
            this.likeButtonTarget.classList.add('text-red-500');
            this.likeCountTarget.innerText = likeCount
            this.currentUserLikedValue = true
          } else {
            console.log(res_body.error)
          }
        })
        .catch(error => {})
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }
}
