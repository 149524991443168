import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js"
import PhotoSwipe from "photoswipe/dist/photoswipe.esm.js"

document.addEventListener("turbolinks:load", function() {
  const lightbox = new PhotoSwipeLightbox({
    gallery: ".gallery-viewer",
    children: "a",
    showHideAnimationType: "fade",
    pswpModule: PhotoSwipe
  });
  lightbox.init();
})
