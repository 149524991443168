import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="community--follows"
export default class extends Controller {
  static values = { followUrl: String, unFollowUrl: String, currentUserFollowed: Boolean, userId: Number, followId: Number }
  static targets = ['followButton']

  toggleFollow() {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.getMetaValue('csrf-token')
    }

    if (this.currentUserFollowedValue) {
      fetch(this.unFollowUrlValue.replace(0, this.followIdValue), {
        method: 'DELETE',
        headers: headers
      }).then(response => response.json())
        .then(res_body => {
          if (res_body.success) {
            this.followButtonTarget.classList.remove('bg-white', 'text-blue-800');
            this.followButtonTarget.classList.add('bg-blue-800', 'text-white');
            this.followButtonTarget.innerText = 'Follow';
            this.currentUserFollowedValue = false;
            this.followIdValue = 0;
          } else {
            console.log(res_body.error)
          }
        })
        .catch(error => {})
    } else {

      fetch(this.followUrlValue, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({follow: {followed_id: this.userIdValue}})
      }).then(response => response.json())
        .then(res_body => {
          if (res_body.success) {
            this.followButtonTarget.classList.remove('bg-blue-800', 'text-white');
            this.followButtonTarget.classList.add('bg-white', 'text-blue-800');
            this.followButtonTarget.innerText = 'Unfollow';
            this.currentUserFollowedValue = true;
            this.followIdValue = res_body.follow.id;
          } else {
            console.log(res_body.error)
          }
        })
        .catch(error => {})
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }
}
