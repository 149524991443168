import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "visibleCode", "hiddenCouponForm", "hiddenCode", "hiddenEmptyCartForm" ]

  applyCoupon() {
    this.hiddenCodeTarget.value = this.visibleCodeTarget.value;
    this.hiddenCouponFormTarget.submit();
  }

  emptyCart() {
    this.hiddenEmptyCartFormTarget.submit();
  }
}
